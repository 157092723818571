<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" />
        <v-row>
          <v-col
            cols="12"
            :md="showHelp ? 12 : 6"
            sm="3"
            class="pr-0"
            v-for="item in views"
            :key="item.id"
          >
            <v-card
              color="primary"
              dark
              @click="whereToPush(item)"
              height="100px"
            >
              <v-card-title class="text-h5">
                {{ item.title }}
              </v-card-title>

              <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConfiguracionMesaEntrada",
  components: { PageHeader, Ayuda },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.setPermisos();
    this.views.sort(function(a, b) {
      return a.order - b.order;
    });
  },
  data() {
    return {
      title: enums.titles.CONFIGURACION_MESA_ENTRADA,
      allowedActions: null,
      optionCode: enums.webSiteOptions.CONFIGURACION_MESA_ENTRADA,
      views: [],
      showExpand: false,
      showHelp: false,
      showIcon: true
    };
  },
  methods: {
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.webSiteOptions.GESTION_TIPOS_EXPEDIENTES:
            this.views.push({
              id: -1,
              order: 1,
              title: x.title,
              //   subtitle:
              //     "Permite configurar la credencial imagen que se muestra en la aplicación.",
              path: "mvc",
              params: `${window.location.origin}/redirto?id=TipoExpediente/ConsultaTipoExp`
            });
            break;
          case enums.webSiteOptions.TIPOS_DE_EXPEDIENTES:
            this.views.push({
              id: 3,
              order: 2,
              title: x.title,
              subtitle:
                "Configuración de los tipos de expediente que se pueden cargar desde la App (Afiliados) o el Sitio de autorizaciones (Prestadores y proveedores).",
              path: "TiposDeExpedientesApp"
            });
            break;
          case enums.webSiteOptions.SECTORES:
            this.views.push({
              id: 1,
              order: 3,
              title: x.title,
              subtitle:
                "Sectores de la institución por donde pueden circular los expedientes.",
              path: "Sectores"
            });
            break;
          case enums.webSiteOptions.GENERADORES_EXPEDIENTES_MESA_ENTRADA:
            this.views.push({
              id: 4,
              order: 4,
              title: x.title,
              subtitle:
                "Otras figuras genéricas que pueden generar un expediente y que no son afiliados, prestadores ni proveedores.",
              path: "GeneradoresExpedientes"
            });
            break;
          case enums.webSiteOptions.DESTINOS_DESPACHOS:
            this.views.push({
              id: 2,
              order: 5,
              title: x.title,
              //   subtitle:
              //     "Permite crear, editar y eliminar la configuración para mercado pago.",
              path: "DestinosDespachos"
            });
            break;
          // case enums.webSiteOptions.OBRAS_SOCIALES_MESA_ENTRADA:
          //   this.views.push({
          //     id: -2,
          //     order: 6,
          //     title: x.title,
          //     subtitle:
          //       "Obras sociales para indicar en la carga de Reintegros que el afiliado tiene otras coberturas.",
          //     path: "mvc",
          //     params: `${window.location.origin}/redirto?id=ObrasSocialesOtrasCoberturas/ConsultaObrasSocialesOtrasCoberturas`
          //   });
          //   break;
          case enums.webSiteOptions.CONFIGURACION_REPLICACION:
            this.views.push({
              id: 5,
              order: 6,
              title: x.title,
              subtitle:
                "Configuración para replicar comprobantes que se registran desde Mesa de entrada Web.”",
              path: "ConfigReplicacion"
            });
            break;
        }
      });
    },
    whereToPush(item) {
      if (item.id < 0) {
        this.$router.push({
          name: item.path,
          params: { url: encodeURIComponent(item.params) }
        });
      } else {
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
          opaque: true
        });
        this.$router.push({
          name: item.path
        });
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
::v-deep .text-h5 {
  font-size: 1.35rem !important;
  font-weight: 450;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
